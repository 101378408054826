// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
        production: false,
        stripe_token: 'STRIPE_TOKEN',
        paypal_token: 'PAYPAL_TOKEN',
        loadGif: 'assets/images/loader.gif',

        loading: {
            spinner: 'circles',
            duration: 3000
        },

        // PARAMETROS ALANIANS
        firebase: {
            apiKey: "AIzaSyD1Qko8XLxFP8zuufMeXe3KCSocw-mVAKo",
            authDomain: "alanians-pro.firebaseapp.com",
            projectId: "alanians-pro",
            storageBucket: "alanians-pro.appspot.com",
            messagingSenderId: "273707274282",
            appId: "1:273707274282:web:ee75d24b6110fe43d8f353",
            measurementId: "G-Z57M10R51B"
        },
        urlBase: 'https://us-central1-alanians-pro.cloudfunctions.net',
        urlBaseWompy: 'https://us-central1-alanians-pro.cloudfunctions.net/Wompi',
        apikey: '500126a8efb-760bf55db7bff-404e-644437de',
        bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/alanians-pro.appspot.com/o/assets%2Fimages%2Fbackground%2Fianos-banner-default-_alanians-1900x600px.jpg?alt=media&token=836405d0-d124-4bb4-b6db-1611a9c18247',

        // CREDENCIALES DE PRO ITWC
        wompi: {
            grant_type: 'client_credentials', // Dejar siempre client_credentials.
            audience: 'wompi_api', // Dejar siempre "wompi_api"
            client_id: 'dc4c16b2-08d9-4478-9037-40a580004f6b', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
            client_secret: '2c1c79a5-8315-4989-8ade-1469183c0810', //Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        },


        // TWILIO CREDENCIALES IanOS APP
        twilio: {
            TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/Verifications',
            TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA5681a04e24d8bc289d6008f39af37744/VerificationCheck`,
            TWILIO_AUTH_TOKEN: '97cd517dd6104fe0d1a9e6595510487f',
            TWILIO_ACCOUNT_SID: 'AC3cd8a9a45ccbb432105181d79f45b95d',
            TWILIO_SERVICE_TOKEN: 'VA5681a04e24d8bc289d6008f39af37744'
        },

        countCharacter(string) {
            let limit = (this.width < 1560) ? 25 : 45;
            if (string.length > limit) {
                string = string.substring(0, limit) + '...';
            }
            return string;
        },

        breadcrumbDynamic(arrayBreadcrumb) {
            let sendBreadcrumbDynamic = [];
            arrayBreadcrumb.forEach(element => {
                if (element != '') {
                    let info = {
                        'name': element.replaceAll('-', ' '),
                        'url': element,
                    };
                    sendBreadcrumbDynamic.push(info);
                }
            });
            return sendBreadcrumbDynamic;
        }
    }
;

