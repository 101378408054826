import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductDetailsMainSlider, ProductDetailsThumbSlider} from '../../../../shared/data/slider';
import {Product} from '../../../../shared/classes/product';
import {ProductService} from '../../../../shared/services/product.service';
import {SizeModalComponent} from "../../../../shared/components/modal/size-modal/size-modal.component";
import {HttpService} from "../../../../services/http.service";
import {FirestoreService} from "../../../../services/firestore.service";
import {environment} from "../../../../../environments/environment";
import {element, promise} from "protractor";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-product-left-sidebar',
    templateUrl: './product-left-sidebar.component.html',
    styleUrls: ['./product-left-sidebar.component.scss']
})
export class ProductLeftSidebarComponent implements OnInit {

    public product: Product = {};
    public counter: number = 1;
    public activeSlide: any = 0;
    public selectedSize: any;
    public mobileSidebar: boolean = false;
    public active = 1;
    findProduct: boolean;

    @ViewChild("sizeChart") SizeChart: SizeModalComponent;

    public ProductDetailsMainSliderConfig: any = ProductDetailsMainSlider;
    public ProductDetailsThumbConfig: any = ProductDetailsThumbSlider;

    public urlCategory: string = '';
    public urlSubcategory: string = '';
    public urlClassification: string = '';
    public urlProduct: string = '';
    public urlComplete: string = '';
    public url: string = '';
    public sendBreadcrumbDynamic: any [] = [];
    public categories: any [] = [];
    public subcategories: any [] = [];
    public classifications: any [] = [];
    //public txtExtrac: string = '/products';
    public txtExtrac: string[] = ['/products', '/productos'];
    public dataApi = {
        'limit': 1,
        'offset': 1,
        'type': 4,
        'urlComplete': '',
        'url': '',
    }
    showError: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: HttpService,
        private _sanitizer: DomSanitizer,
        private firestore: FirestoreService,
        public productService: ProductService
    ) {


        // console.log('url completa prueba', this.url.substring(this.txtExtrac.length));

    }

    ngOnInit(): void {
        this.url = this.router.url;
        let arrayBreadcrumb = this.url.split('/');
        this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);

        this.urlCategory = this.route.snapshot.paramMap.get('category');
        this.urlSubcategory = this.route.snapshot.paramMap.get('subcategory');
        this.urlClassification = this.route.snapshot.paramMap.get('classification');
        this.urlProduct = this.route.snapshot.paramMap.get('product');
        /*this.urlComplete = '/'+this.urlCategory+'/'+this.urlSubcategory+'/'+this.urlClassification;
        console.log('this.urlProduct', this.route.snapshot.paramMap.get('product'));
        if (this.urlProduct != null ) this.urlComplete.concat('/'+this.urlProduct);*/
        //console.log('this.urlProduct', this.urlProduct);
        // this.urlComplete = this.url.substring(this.txtExtrac.length);

        this.urlComplete = this.url.replace(this.txtExtrac[0],'');
        this.urlComplete = this.urlComplete.replace(this.txtExtrac[1],'');

        this.getCategoryByUrl();
        /** this.service.getSubcategoryByUrl(this.urlSubcategory).subscribe(res => {
            if (res.success) {
                // this.classifications = res.classifications;
            }
        }) */
        this.getCategories();
        // this.urlComplete = this.url.substring(this.txtExtrac.length);
        this.getProduct().catch(res => {
            console.log('response by catch', res);
        });
    }

    getProduct() {
        return new Promise((resolve, reject) => {
            let data = this.dataApi;
            data.type = 4;
            data.urlComplete = this.urlComplete;
            this.service.getOneProductByUrl(data).subscribe(res => {
                console.info('response getProductByUrl', res);
                if (res.success && (typeof res.product === 'object' || res.product instanceof Map)) {
                    this.findProduct = true;
                    let element = res.product;
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description     = element.product_description || element.specifications;
                    element.brand           = element.marca;
                    element.sale            = element.product_active;
                    element.price           = element.price_tax_product;
                    if (element.product_promote) element.discount = element.product_discount || element.discount;
                    element.stock           = element.product_stock || element.products_stock;
                    element.images          = [];
                            // console.log('element.extra_images', element.extra_images)
                    if (element.extra_images != null) {
                        element.extra_images.forEach( index => {
                            // console.log('index', index)
                            let imgExtra = {
                                "src": index.url_image || index.src || index,
                                "alt": element.product_name,
                            }
                            element.images.push(imgExtra);
                        })
                    }
                    img.src = element.product_imagesm;
                    element.images.push(img);
                    this.product = element;
                } else {
                    console.error('no tiene ');
                    this.goSubcategory();
                }

                resolve(res);
            }, error => {
                reject(error);
            })
        })
    }

    getProductsByUrl(){
        this.dataApi.type = 2;
        this.dataApi.limit = 50;
        this.dataApi.url  = this.urlSubcategory;
        this.service.getProductsByUrl(this.dataApi).subscribe( response2 => {
            // console.log('response by subcategorie', response2);
            if (response2.success){
                response2.products.forEach( element => {
                    if (element.url == this.urlProduct){
                        let img = {
                            "src": '',
                            "alt": element.product_name,
                        }
                        element.title = element.product_name;
                        element.description = element.specifications;
                        element.brand = element.marca;
                        element.sale = element.product_active;
                        element.price = element.product_price;
                        element.discount = element.product_discount;
                        element.stock = element.products_stock;
                        element.images = [];
                        if (element.extra_images != null) {
                            element.extra_images.forEach(index => {
                                img.src = index.url_image || index.src || index;
                                element.images.push(img);
                            })
                        }
                        img.src = element.product_imagesm;
                        element.images.push(img);
                        this.product = element;
                        this.findProduct = true;
                    }
                })

            } else {
                this.showError = true;
            }
        })
    }

    goSubcategory(){
        let urlSubcategory = this.urlComplete.replace(this.urlClassification,'');
        //console.log('urlSubcategory', urlSubcategory);
        this.router.navigate(['/productos'+urlSubcategory]);
    }

    getCategoryByUrl(){
        this.service.getCategoryByUrl(this.urlCategory).subscribe((res) => {
            // console.log('getCategoryByUrl', res);
            if (res.subcategories.length > 0) {
                res.subcategories.forEach(element => {
                    element.url = this.urlCategory + '/' + element.url;
                    this.subcategories.push(element);
                })
            }
        });
    }

    getCategories(){
        this.service.getCategories().subscribe((res) => {
            res.forEach(element => {
                if (element.category_active && element.category_disabled) {
                    element.name = element.category_name;
                    element.url = element.url_category;
                    this.categories.push(element);
                }
            })
        });
    }

    // Increament
    increment() {
        this.counter++;
    }

    // Decrement
    decrement() {
        if (this.counter > 1) this.counter--;
    }

    // Add to cart
    async addToCart(product: any) {
        console.log('product by cart', product);
        // product.quantity = this.counter || 1;
        let status = await this.productService.addToCart(product);
        if (status) await this.router.navigate(['/shop/cart']);
    }

    // Buy Now
    async buyNow(product: any) {
        product.quantity = this.counter || 1;
        const status = await this.productService.addToCart(product);
        if (status)
            this.router.navigate(['/shop/checkout']);
    }

    // Toggle Mobile Sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }



}
