<!--<app-breadcrumb [title]="infoCategory.nameCategory" [breadcrumb]="" [breadcrumbDynamic]="sendBreadcrumbDynamic"></app-breadcrumb>-->
<!-- section start -->
<section class="section-b-space pt-0 gym-product">
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="collection-filter" [class.openFilterbar]="mobileSidebar">
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <div class="collection-mobile-back" (click)="toggleMobileSidebar()">
                                    <span class="filter-back">
                                    <i class="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                            </div>

                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar colleps block stat -->
                        <div class="collection-filter-block">
                            <!--Categorias-->
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="categories.length">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Categoría</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                             *ngFor="let item of categories; let i = index">
                                            <input
                                                    type="checkbox"
                                                    [value]="item.name"
                                                    [checked]="isChecked(item.name, categoriesSelected)"
                                                    [id]="item.name"
                                                    (change)="handleSelection($event, categoriesSelected)"
                                                    class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ item.name }}">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Marcas-->
                            <div [hidden]="true" class="collection-collapse-block" [class.open]="collapse" *ngIf="brands.length">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">Marcas</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                             *ngFor="let item of brands; let i = index">
                                            <input
                                                    type="checkbox"
                                                    [value]="item.name"
                                                    [checked]="isChecked(item.name, brandsSelected)"
                                                    [id]="item.name"
                                                    (change)="handleSelection($event, brandsSelected)"
                                                    class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ item.name }}">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Descuento-->
                            <div class="collection-collapse-block" [class.open]="collapse" *ngIf="percents.length">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse">% Descuento</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="collection-brand-filter">
                                        <div class="custom-control custom-checkbox collection-filter-checkbox"
                                             *ngFor="let item of percents; let i = index">
                                            <input
                                                    type="checkbox"
                                                    [value]="item.name"
                                                    [checked]="isChecked(item.name, percentsSelected)"
                                                    [id]="item.name"
                                                    (change)="handleSelection($event, percentsSelected)"
                                                    class="custom-control-input" />
                                            <label class="custom-control-label" for="{{ item.name }}">{{ item.name }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- silde-bar colleps block end here -->
                        <!-- side-bar single product slider start -->
                        <app-product-box-vertical-slider [title]="'Nuevos productos'">
                        </app-product-box-vertical-slider>
                        <!-- side-bar single product slider end -->
                        <!-- side-bar banner start here -->
                        <div [hidden]="true" class="collection-sidebar-banner">
                            <a [routerLink]="['/shop/collection/left/sidebar']">
                                <img src="assets/images/collection-sidebar-banner.png" class="img-fluid" alt="banner">
                            </a>
                        </div>
                        <!-- side-bar banner end here -->
                    </div>
                </div>
                <div class="collection-content col">
                    <div class="page-main-content">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn" (click)="toggleMobileSidebar()">
                                            <span class="filter-btn btn btn-theme">
                                            <i class="fa fa-filter" aria-hidden="true"></i> Filtro
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul class="product-filter-tags">
                                    <li class="me-1" *ngFor="let tag of tags">
                                        <a href="javascript:void(0)" class="filter_tag">
                                            {{tag}} <i class="ti-close" (click)="removeTag(tag)"></i>
                                        </a>
                                    </li>
                                    <li class="clear_filter" *ngIf="tags.length > 0">
                                        <a href="javascript:void(0)" class="clear_filter" (click)="removeAllTags()">
                                            Borrar todo
                                        </a>
                                    </li>
                                </ul>
                                <div class="collection-product-wrapper" id="products">
                                    <app-grid [products]="products" [paginate]="paginate" [layoutView]="layoutView" [sortBy]="sortBy" (setGrid)="updateGridLayout($event)"
                                              (setLayout)="updateLayoutView($event)" (sortedBy)="sortByFilter($event)">
                                    </app-grid>
                                    <div class="product-wrapper-grid" [ngClass]="layoutView">
                                        <div class="row align-items-center">
                                            <div class="col-grid-box" [ngClass]="grid" *ngFor="let product of products">
                                                <div class="product-box">
                                                    <app-product-box-one
                                                            [product]="product"
                                                            [currency]="productService?.Currency"
                                                            [thumbnail]="false"
                                                            [cartModal]="true">
                                                    </app-product-box-one>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length && showLoading">
                                                <img src="assets/images/loader.gif" *ngIf="loading">
                                                <p>Cargando contenido</p>
                                            </div>
                                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!products.length && !showLoading">
                                                <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                                                <h3>¡Lo sentimos! No se ha encontrado el producto que buscaba. </h3>
                                                <p>Por favor, compruebe si ha filtrado algo mal o intente buscar con otros filtros.</p>
                                                <a [routerLink]="['/products']" class="btn btn-solid">seguir comprando</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-infinitescroll">
                                        <div class="theme-paggination-block">
                                            <div class="container-fluid p-0">
                                                <div class="row">
                                                    <div class="text-center col-sm-12">
                                                        <div class="infinite-scrolling mt-3 mb-3 d-inline-block">
                                                            <img src="assets/images/loader.gif" *ngIf="loading">


                                                            <div *ngIf="!loading && !finished" (click)="onScroll()" class="loading-more active my-3 cursor-pointer">Mostrar más</div>
                                                            <div class="loading-more active" *ngIf="finished && products.length">No más productos</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- section End -->



